import React from "react";

import moment from "moment";
/** Utils */
import {
  AUTHENTICATOR_AUTHSTATE,
} from "./local-storage";


import { isBrowser } from "@utils/Helpers";
import { DEFAULT_USER_AUTH } from "./consts";


export const isLoggedIn = () =>{
  if(!isBrowser) return false;
  const status = localStorage.getItem(AUTHENTICATOR_AUTHSTATE);
  console.log('checking login status: ', status);
  return status === "signedIn"
  
  
}
/** Return user auth object from local storage value */
// export const getStoredUserAuth = (): UserAuth => {
//   if (typeof window !== 'undefined'){
//     const auth = localStorage.getItem(USER_AUTH_KEY);
//     if (auth) {
//       return JSON.parse(auth);
//     }
//   }

//   return DEFAULT_USER_AUTH;
// };
